
/*===================================================================================================
  About Us Section
===================================================================================================*/

.h-about-m1 .btn-primary {
    border-width: 1px;
    border-style: solid;
}

.h-about-m1 .btn-primary:hover,
.h-about-m1 .btn-primary:active,
.h-about-m1 .btn-primary.active {
    background-image: none;
}


.h-about-m1 .spotlight {
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
}

.h-about-m1 .spotlight .image {
    -moz-order: 2;
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
    border-radius: 0;
    width: 30%;
}

.h-about-m1 .spotlight .image img {
    border-radius: 0;
    width: 100%;
}

.h-about-m1 .spotlight .content {
    padding: 20px 4em 20px 4em;
    -moz-order: 1;
    -webkit-order: 1;
    -ms-order: 1;
    order: 1;
    width: 70%;
    line-height: 1.7em;
}

.h-about-m1 .spotlight .content .about-text  {
    line-height: 1.8em;
    font-size: 13px;
    margin-bottom: 25px;
    height: 134px;
    display: -webkit-box;
    max-width: 4400px;
    -webkit-line-clamp:6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media screen and (max-width: 1280px) {
    .h-about-m1 .spotlight .image {
        width: 45%;
    }

    .h-about-m1 .spotlight .content {
        width: 55%;
    }
}

@media screen and (max-width: 980px) {
    .h-about-m1 .spotlight {
        display: block;
    }

    .h-about-m1 .spotlight .image {
        width: 100%;
    }

    .h-about-m1 .spotlight .content {
        padding: 3em;
        max-width: none;
        text-align: left;
        width: 100%;
    }
}

@media screen and (max-width: 736px) {
    .h-about-m1 .spotlight .content {
        padding: 3em 2em 3em 2em;
    }
}
