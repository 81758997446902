/*===================================================================================================
  Global Features
===================================================================================================*/
body {
    font-weight: 400;
    position: relative;
}

/* Links & Buttons
===========================================================*/
a {
    text-decoration: none;
    font-weight: 400;
}

.btn-link,
.btn-link:focus {
    text-decoration: none;
    border-bottom: 1px solid;
}

.btn-link:hover,
.btn-link:active,
.btn-link.active {
    text-decoration: none;
}

.btn-link-color1,
.btn-link-color1:focus {
    text-decoration: none;
    border-bottom: 1px solid transparent;
}

.btn-link-color1:hover,
.btn-link-color1:active,
.btn-link-color1.active {
    text-decoration: none;
}

.btn-link-primary,
.btn-link-primary:focus {
    text-decoration: none;
    text-transform: uppercase;
}

.btn-link-primary:hover,
.btn-link-primary:active,
.btn-link-primary.active {
    text-decoration: none;
}


/* Modal
===========================================================*/
.modal-heading,
.modal-header {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .04em;
    border-bottom: 1px solid;
}


/* Modal Success
===========================================================*/
.modal-success {
    border-bottom: 0;
}

.modal-success h4 {
    font-weight: 400;
    text-transform: none;
}



/* Section Module Padding
===========================================================*/
.welcome-m1,
.h-inventory-carousel-m1,
.h-find-m1,
.h-about-m1 {
    padding: 50px 0;
}
.locations-m1 {
    padding: 10px 0;
}


/* Sections Titles
===========================================================*/

.section-heading {
    padding-bottom: 30px;
}

.section-title {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .02em;
}

.section-title::after {
    content: "";
    display: block;
    height: 3px;
    width: 50px;
    margin-top: 10px;
}


.section-subheading {
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 300;
    margin-top: 10px;
}


.section-heading .section-title-line {
    display: block;
    width: 60px;
    height: 4px;
    background-color: #fff;
    margin-top: 10px;
}


/* Sections Titles
===========================================================*/
.special {
    text-decoration: line-through;
    font-size: 16px;
}

.h-inventory-carouselcompact-m1 .section-heading.hover .section-title {
    text-align: left;
}