/*===================================================================================================
  Top Nav Bar
===================================================================================================*/

.nav-m1 {
    min-height: 60px;
}

@media(max-width: 767px) {
    .nav-m1 {
        min-height: 52px;
    }
}

.nav-m1 .menu {
    padding: 0;
}


.nav-m1 .main-menu {
    position: relative;
    z-index: 8;
}

.nav-m1 .main-menu.affix {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 49000;
}


/*===================================================================================================
  Navigation
===================================================================================================*/
.nav-m1 .navbar {
    border: 0;
    min-height: 60px;
    z-index: 5000;
}

.nav-m1 .navbar-nav > li a {
    display: inline-block;
    letter-spacing: 0.04em;
    font-size: 13px;
    padding: 25px;
    text-transform: uppercase;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
}


.nav-m1 .navbar li a,
.nav-m1 .navbar li a:hover,
.nav-m1 .navbar li a:focus,
.nav-m1 .navbar li a.active,
.nav-m1 .navbar li a:active {
    font-weight: 400;
}

.nav-m1 .navbar-collapse {
    padding: 0;
}


/* Site Logo - Visible in Mobile Size */

.nav-m1 .site-logo h2 {
    margin-top: 13px;
    margin-bottom: 13px;
    font-size: 16px;
    padding-left: 15px;
    text-transform: uppercase;
}


.nav-m1 .site-logo a:hover {
    text-decoration: none;
}

.nav-m1 .site-logo img {
    height: 50px;
    padding-left: 1rem;
}


/* Call Button - Visible in Mobile Size */
.nav-m1 .call-xs i {
    font-size: 1.5em;
}



/* Center Links in Desktop View */
@media (min-width: 768px) {
    .nav-m1 .container-fluid > .navbar-collapse,
    .nav-m1 .container-fluid > .navbar-header,
    .nav-m1 .container > .navbar-collapse,
    .nav-m1 .container > .navbar-header {
        margin-right: -15px;
        margin-left: -15px;
    }

    .nav-m1 .navbar-nav {
        float: none;
        margin: 0 auto;
        text-align: center;
    }

    .nav-m1 .navbar-nav > li {
        float: none;
        display: inline-block;
        margin-right: -4px;
    }
}





@media screen and (max-width: 767px) {
    .nav-m1 .main-menu {
        border-bottom: 1px solid;
    }

    .nav-m1 .navbar-header ul {
        margin-bottom: 0;
    }

    .nav-m1 .navbar-collapse {
        padding: 15px;
    }

    .nav-m1 .navbar-nav {
        margin: 15px;
    }

    .nav-m1 .navbar-nav > li > a {
        padding: 10px;
        display: block;
        border-left: none;
    }

    .nav-m1 .menu-list li a,
    .nav-m1 .menu-list li:first-child a {
        border: 0;
    }
}





/* Navbar Toggle
===========================================================*/

.nav-m1 .navbar-toggle {
    position: relative;
    float: right;
    padding: 12px 18px 13px 18px;
    margin: 0;
    border: none;
    border: 0;
    border-radius: 0;
    height: 52px;
}

.nav-m1 .navbar-toggle:focus {
    border: 0;
    outline: 0;
}

.nav-m1 .navbar-toggle .icon-bar {
    background-color: #fff;
    position: relative;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

.nav-m1 .navbar-toggle.active .icon-bar:nth-of-type(1) {
    top: 6px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.nav-m1 .navbar-toggle.active .icon-bar:nth-of-type(3) {
    top: -6px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.nav-m1 .main-menu .dropdown-menu {
    border: 0
}

.nav-m1 .navbar-nav .dropdown-menu a.dropdown-item {
    display: block;
    padding: 10px;
    margin: 5px;
}

.dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}