/*===================================================================================================
  About Us Page
===================================================================================================*/
.about-m1 {
    padding: 20px 0 60px;
}

.about-m1 p {
    line-height: 2;
}

.about-m1 .paragraph {
    font-size: 16px;
    margin-top: -8px;
    line-height: 35px;
    font-weight: 300;
}

.about-m1 .about-padd {
    padding: 0 25px;
}

.about-m1 .about-info-padd {
    padding: 15px 30px 0;
}

.about-m1 .about-text {
    white-space: pre-line;
}

@media(max-width: 820px) {
    .about-m1 .c-tablecell,
    .about-m1 .c-tablecell-top {
        display: block;
    }

    .about-m1 img {
        width: 100%;
    }
}
