body.font-Poppins .font-primary {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 400;
}

body.font-Poppins .page-title-headings h2,
body.font-Poppins .page-title-headings h1 {
    letter-spacing: 0.05em;
}

body.font-Poppins .vehicle-link .font-primary {
    letter-spacing: .02em;
}

body.font-Poppins .site-logo h2 {
    margin-top: 16px;
}

body.font-Poppins .page-title-headings h2,
body.font-Poppins .page-title-headings h1,
body.font-Poppins .section-title h2,
body.font-Poppins .section-title h1,
body.font-Poppins .section-title {
    font-size: 26px;
}

body.font-Poppins .staff-member .staff-photo .staff-name,
body.font-Poppins .staff-member .staff-photo .staff-name span {
    font-weight: 400;
}
