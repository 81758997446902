/* Breadcrumb */
.inventory-m1 .breadcrumb {
    padding: 0 0 10px 0;
    background: transparent;
    margin-bottom: 0;
}

/* Price Fields */

.inventory-m1 .to {
    display: inline-block;
    float: left;
    font-size: 11px;
    padding-top: 10px;
    text-align: center;
    width: 6%;
}