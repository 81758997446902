/*===================================================================================================
  Testimonials
===================================================================================================*/

.testimonials-m1 {
    padding: 20px 0 60px;
}

.testimonials-m1 img {
    max-width: 300px;
    border-bottom: 3px solid;
}

.testimonials-m1 .testimonials-info {
    padding: 0 20px;
    width: 100%;
}

.testimonials-m1 .testimonials-info h4 {
    font-weight: 700;
    font-size: 20px;
}

.testimonials-m1 .testimonials-info .testimonial {
    line-height: 24px;
}

.testimonials-m1 .testimonials-info .testimonials-customer {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}

.testimonials-m1 .testimonials-info .testimonials-location {
    font-style: italic;
    margin-top: 0;
}

.testimonials-m1 .testimonial-spacer {
    margin: 0 0 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid;
}


.testimonials-m1 .customer-location-border {
    border-left: 2px solid;
    padding-left: 10px;
    margin-left: 20px;
    color: #fff;
}


@media screen and (max-width: 991px) {
    .testimonials-m1 img {
        margin-left: 0;
    }

    .testimonials-m1 .testimonial-spacer {
        padding-top: 15px;
    }
}

@media(max-width: 767px) {
    .testimonials-m1 .c-tablecell,
    .testimonials-m1 .c-tablecell-top {
        display: block;
    }

    .testimonials-m1 .c-tablecell:first-of-type {
        padding-right: 0;
        text-align: center;
        margin-bottom: auto;
        width: auto;
    }

    .testimonials-m1 .c-tablecell:nth-of-type(2) {
        padding-left: 15px;
        padding-top: 20px;
    }

    .testimonials-m1 img {
        width: 100%;
        max-width: none;
    }
}


.testimonials-m1 .fileinput-filename {
    margin-left: 10px;
}

.testimonials-m1 .fileinput .close {
    margin-top: 13px;
    margin-left: 6px;
    font-size: 15px;
}
