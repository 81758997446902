body.font-Ubuntu .font-primary {
    font-family: 'Ubuntu', sans-serif;
    text-transform: none;
    letter-spacing: .05em;
    font-weight: 400;
}

body.font-Ubuntu .vehicle-link .font-primary {
    letter-spacing: .02em;
}

body.font-Ubuntu .section-title,
body.font-Ubuntu .page-title-headings h2,
body.font-Ubuntu .page-title-headings h1,
body.font-Ubuntu .locations-section .dealer-header,
body.font-Ubuntu .vehicle-title,
body.font-Ubuntu .vehicle-card .vehicle-title,
body.font-Ubuntu .site-logo h2,
body.font-Ubuntu .hero-overlay h2,
body.font-Ubuntu .section-title h2,
body.font-Ubuntu .section-title h1,
body.font-Ubuntu .dealer-name,
body.font-Ubuntu .modal-title,
body.font-Ubuntu .staff-member .staff-photo .staff-name,
body.font-Ubuntu .staff-member .staff-photo .staff-name span,
body.font-Ubuntu .header-logo a {
    text-transform: none;
    letter-spacing: 0.05em;
    font-weight: 400 !important;
}
