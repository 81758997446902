/* 404
===========================================================*/
#error-404-m1 {
    padding: 60px 0;
}

#error-404-m1 .section-title {
    font-size: 140px;
}

#error-404-m1 .section-title::after {
    display: none;
}

#error-404-m1 .section-subheading {
    color: #595959;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-weight: 500;
}
