.h-find-m1 .section-title::after {
    content: "";
    display: block;
    height: 3px;
    width: 50px;
    margin-top: 10px;
}


.h-find-m1 label { /*sr-only css*/
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

@media (min-width: 768px) {
    .h-find-m1 {
        padding: 50px 20px;
    }
}

@media (max-width: 991px) {
    .h-find-m1 label {
        display: block;
    }
}
